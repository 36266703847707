import { jsPDF } from "jspdf";
import "jspdf-barcode";
import useHttp from '@/comp-functions/useHttp'
import moment from 'moment'
//import { Base64 } from 'js-base64';
// import { ref } from '@vue/composition-api'
import {formatNumber} from '@/utils/formatter'
export default function printInvoiceSmu(props) {

  const { $get } = useHttp()

  const getData = async () => {
    let dataMst = await $get({ url: 'master/smu_print_mst/' + props})
    let dataDtl = await $get({ url: 'master/smu_print_dtl/'+ props})
    dataMst = dataMst.data
    dataDtl = dataDtl.data
    generateLabel(dataMst,dataDtl)
  }

  getData()
  const generateLabel = (dataMst, dataDtl) => {
    let pdfName = 'SMU';
    let dtlsurchargedesc = '';
    let curx = 0;
    let cury = 0;
    const doc = new jsPDF();
    const barcode_no = dataMst[0].ord_manifestno;
    const manifestno_split_1 = dataMst[0].ord_manifestno.substring(0, 3) 
    let manifestno_split_2 = dataMst[0].ord_manifestno.substring(3) 
      manifestno_split_2 = manifestno_split_2[0] == '-' ? manifestno_split_2.substring(1) : manifestno_split_2

    let rect_width = 203;
    let rect_height = 270;
    // Kotak luar
    doc.rect(3, 10, rect_width, rect_height, 'S')

    doc.setFont("helvetica", "bold");
    doc.text(manifestno_split_1, 8, 7, {align: "left"});

    doc.line(19, 2, 19, 10, 'S')

    doc.text(dataMst[0].airport_departure_code, 21, 7, {align: "left"});

    doc.line(35, 2, 35, 10, 'S')

    doc.text(manifestno_split_2, 37, 7, {align: "left"});

    doc.text(barcode_no, 200, 7, {align: "right"});

    let line_left_to_mid = rect_width/2
    let end_line_mid = (rect_height/2)-15

    // Garis Tengah vertikal
    doc.line(line_left_to_mid, 10, line_left_to_mid, end_line_mid, 'S')

    doc.setFont("helvetica","");
    doc.setFontSize(7)
    doc.text("Shipper's Name and Address", 4, 13, {align: "left"});

    doc.text(dataMst[0].ord_shipper_name, 5, 16, {align: "left"});
    doc.text(dataMst[0].ord_shipper_address, 5, 23, {align: "left"});

    // doc.setFontSize(10)
    // doc.text("12345678910", 13, 29, {align: "left"});

    // Kotak Account Number
    let line_x = line_left_to_mid/2
    let line_y = 19
    doc.line(line_x, 10, line_x, line_y, 'S')
    doc.line(line_x, line_y, line_x*2, line_y, 'S')

    doc.setFontSize(7)
    doc.text("Shipper's Account Number", 74, 12.5, {align: "center"});

    doc.line(3, 34, line_left_to_mid, 34, 'S')

    doc.setFontSize(7)
    doc.text("Not Negotiable", 111, 12.5, {align: "center"});

    doc.setFontSize(8)
    doc.setFont("helvetica", "bold");
    doc.text("Air Waybill", 111, 15.5, {align: "center"});

    doc.setFontSize(6)
    doc.setFont("helvetica", "");
    doc.text("issued by", 108, 17.5, {align: "center"});

    doc.setFontSize(8)
    doc.setFont("helvetica", "");
    doc.text(dataMst[0].vendor_name, 108, 21, {align: "center"});

    doc.setFontSize(8)
    doc.setFont("helvetica", "");
    doc.text(dataMst[0].vendor_address, 104, 24, {align: "left"});

    let line_mid_to_right = (line_left_to_mid*2)+3
    let mid_to_right_start = line_mid_to_right/2

      // Kotak Air Waybill
    let imgurl = new Image()
    imgurl.src = process.env.VUE_APP_BASEURL + require(`@/assets/images/vendorlogo/${dataMst[0].vendor_logo_ve}`)
    doc.addImage(imgurl, "PNG", line_mid_to_right-50, 12, 50, 13);

    doc.line(line_left_to_mid, 27, line_mid_to_right, 27, 'S')
    doc.text("Copies 1, 2 and 3 of this Air Waybill are originals and have the same validity", 104, 32, {align: "left"});

    doc.line(line_left_to_mid, 34, line_mid_to_right, 34, 'S')

      // Kotak Consignee name and adress
    doc.setFont("helvetica","");
    doc.setFontSize(7)
    doc.text("Consignee's Name and Address", 4, 37, {align: "left"});

    doc.text(dataMst[0].ord_consignee_name, 5, 40, {align: "left"});
    // doc.text("PAK LINDU", 5, 43, {align: "left"});
    doc.text(dataMst[0].ord_consignee_address, 5, 49, {align: "left"});

    doc.setDrawColor(0);
    doc.setFillColor(231, 219, 213);  // Warna Kotak
    doc.rect(line_x, 34, line_x, 10, 'FD')
    doc.text("Consignee's Account Number", 74, 36.5, {align: "center"});

    doc.line(3, 59, line_left_to_mid, 59, 'S')
      
    // Kotak it is agreed
    doc.setFontSize(7)
    doc.setFont("helvetica", "");
    doc.text("it is agreed that the goods declared herein are accepted in apparent good order and condition (except as", mid_to_right_start-1, 37, {align: "left"});
    doc.text("noted) for carriage SUBJECT TO THE CONDITIONS OF CONTRACT ON THE REVERSE HEREOF,", mid_to_right_start-1, 40, {align: "left"});
    doc.setFontSize(6.8)
    doc.text("ALL GOODS MAY BE CARRIED BY ANY OTHER MEANS INCLUDING ROAD OR ANY OTHER", mid_to_right_start-1, 42.5, {align: "left"});
    doc.text("CARRIER UNLESS SPECIFIC CONTRARY INSTRUCTIONS ARE GIVEN HEREON BY THE", mid_to_right_start-1, 45, {align: "left"});
    doc.setFontSize(6.7)
    doc.text("SHIPPER, AND SHIPPER AGREES THAT THE SHIPMENT MAY BE CARRIED VIA INTERMEDIATE", mid_to_right_start-1, 47.5, {align: "left"});
    doc.setFontSize(6.8)
    doc.text("STOPPING PLACES WHICH THE CARRIER DEEMS APPROPRIATE. THE SHIPPER'S ATTENTION", mid_to_right_start-1, 50, {align: "left"});
    doc.text("IS DRAWN TO THE NOTICE CONCERNING CARRIER'S LIMITATION OF LIABILITY. Shipper may", mid_to_right_start-1, 53, {align: "left"});
    doc.text("increase such limitation of liability by declaring a higher value for carriage and paying a supplemental", mid_to_right_start-1, 55.5, {align: "left"});
    doc.text("charge if required", mid_to_right_start-1, 58, {align: "left"});

    doc.line(line_left_to_mid, 59, line_mid_to_right, 59, 'S')

      // Kotak Carrier's agent name and citu
    doc.setFont("helvetica","");
    doc.setFontSize(7)
    doc.text("Issuing Carrier's Agent Name and City", 4, 62, {align: "left"});

    doc.text(dataMst[0].cust_name, 5, 67, {align: "left"});
    doc.text(dataMst[0].cust_address, 5, 70, {align: "left"});

    doc.line(3, 77, line_left_to_mid, 77, 'S')

      // Kotak Agent's IATA CODE
    doc.setFont("helvetica","");
    doc.setFontSize(7)
    doc.text("Agent's IATA Code", 4, 79.5, {align: "left"});

    doc.line(line_x, 77, line_x, 87, 'S')

      // Kotak Account no
    doc.text("Account No.", 51.5, 79.5, {align: "left"});

    doc.line(3, 87, line_left_to_mid, 87, 'S')

      // Kotak Accounting Information
    doc.text("Accounting Information", 102, 62, {align: "left"});

      // Kotak Barcode
    doc.setFontSize(10)
    doc.text("FREIGHT PREPAID", 103, 68, {align: "left"});
    doc.text("AIRPORT TO AIRPORT", 103, 72, {align: "left"});

    doc.barcode(barcode_no, {
        fontSize: 40,
        textColor: "#000000",
        x: 149,
        y: 75
    })

    doc.setFont("helvetica","");
    doc.setFontSize(12)
    doc.text(barcode_no, 163, 79, {align: "left"});

    doc.line(line_left_to_mid, 87, line_mid_to_right, 87, 'S')

    doc.setFont("helvetica","");
    doc.setFontSize(7)
    doc.text("Airport of Departure (Addr. of First Carrier) and Requested Routing", 4, 89.5, {align: "left"});

    doc.setFont("helvetica","");
    doc.setFontSize(8)
    doc.text(`${dataMst[0].airport_departure_code}-${dataMst[0].airport_departure_name}`, 4, 93.5, {align: "left"});

    doc.line(3, 95, line_left_to_mid, 95, 'S')

    doc.setFont("helvetica","");
    doc.setFontSize(7)
    doc.text("To", 4, 97.5, {align: "left"});

    doc.setFont("helvetica","");
    doc.setFontSize(9)
  doc.text(dataMst[0].airport_destination_code, 6, 103, {align: "left"});

    doc.line(16, 95, 16, 107, 'S')

    doc.setFont("helvetica","");
    doc.setFontSize(7)
    doc.text("By First Carrier", 17, 97.5, {align: "left"});

    doc.setFont("helvetica","");
    doc.setFontSize(9)
    doc.text("ID", 19, 103, {align: "left"});

      // trapesium kebalik routing and destination
    doc.lines([[4,4],[20,0],[5,-4]], 33, 95, [1,1], 'S', false)

    doc.setFont("helvetica","");
    doc.setFontSize(6)
    doc.text("Routing and Destination", 37, 97, {align: "left"});

    doc.line(63, 95, 63, 107, 'S')

    doc.setFont("helvetica","");
    doc.setFontSize(7)
    doc.text("to", 64, 97, {align: "left"});

    doc.line(74, 95, 74, 107, 'S')

    doc.setFont("helvetica","");
    doc.setFontSize(7)
    doc.text("by", 75, 97, {align: "left"});

    doc.line(83, 95, 83, 107, 'S')

    doc.setFont("helvetica","");
    doc.setFontSize(7)
    doc.text("to", 84, 97, {align: "left"});

    doc.line(92, 95, 92, 107, 'S')

    doc.setFont("helvetica","");
    doc.setFontSize(7)
    doc.text("by", 93, 97, {align: "left"});

    doc.line(3, 107, line_left_to_mid, 107, 'S')

    doc.setFont("helvetica","");
    doc.setFontSize(7)
    doc.text("Airport of Destination", 16, 109.5, {align: "left"});

    doc.setFont("helvetica","");
    doc.setFontSize(8)
    doc.text(`${dataMst[0].airport_destination_code}-${dataMst[0].airport_destination_name}`, 4, 115, {align: "left"});
    // doc.text("HASANUDDIN", 4, 118, {align: "left"});

    doc.line(line_x, 107, line_x, 120, 'S')

    doc.lines([[4,4],[20,0],[5,-4]], 62, 107, [1,1], 'S', false)

    doc.setFont("helvetica","");
    doc.setFontSize(6)
    doc.text("For Carrier Use Only", 67, 109.5, {align: "left"});

    doc.line(77, 111, 77, 120, 'S')

    doc.text("Flight/Date", 59, 113, {align: "left"});

    doc.setFont("helvetica","bold");
    doc.setFontSize(8)
    doc.text(dataMst[0].flight_date, 55, 117, {align: "left"});

    doc.setFont("helvetica","");
    doc.setFontSize(6)
    doc.text("Flight/Date", 84, 113, {align: "left"});

    doc.line(3, end_line_mid, line_left_to_mid, end_line_mid, 'S')

      // Kotak optional shipping information
      doc.lines([[4,2.5],[33,0],[5,-2.5]], 132, 87, [1,1], 'S', false)

      doc.setFont("helvetica","");
      doc.setFontSize(6)
      doc.text("Optional Shipping Information", 140, 89, {align: "left"});

      doc.line(136, 89.5, 136, 95, 'S')

      doc.line(169, 89.5, 169, 95, 'S')

      doc.line(line_left_to_mid, 95, line_mid_to_right, 95, 'S')

      doc.setFont("helvetica","");
      doc.setFontSize(7)
      doc.text("Currency", 102.5, 97.5, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(9)
      doc.text("IDR", 104, 104, {align: "left"});

      doc.setDrawColor(0);
      doc.setFillColor(231, 219, 213);  // Warna Kotak
      doc.rect(line_left_to_mid+11, 95, 6, 12, 'FD')

      doc.setFont("helvetica","");
      doc.setFontSize(5)
      doc.text("CHGS", 113.2, 97.5, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(9)
      doc.text("P", 114, 104, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(4)
      doc.text("WT/VAL", 120, 97, {align: "left"});

      doc.line(118.5, 98, 136, 98, 'S')

      doc.setFont("helvetica","");
      doc.setFontSize(4)
      doc.text("PPD", 119, 99.7, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(9)
      doc.text("P", 119.3, 104, {align: "left"});

      doc.line(122.5, 98, 122.5, 107, 'S')

      doc.setFont("helvetica","");
      doc.setFontSize(4)
      doc.text("COLL", 122.7, 99.7, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(9)
      doc.text("P", 123, 104, {align: "left"});

      doc.line(126.7, 95, 126.7, 107, 'S')

      doc.setFont("helvetica","");
      doc.setFontSize(4)
      doc.text("Other", 130, 97, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(4)
      doc.text("PPD", 127.5, 99.7, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(9)
      doc.text("P", 127.5, 104, {align: "left"});

      doc.line(131.5, 98, 131.5, 107, 'S')

      doc.setFont("helvetica","");
      doc.setFontSize(4)
      doc.text("COLL", 132, 99.7, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(9)
      doc.text("P", 132, 104, {align: "left"});

      doc.line(136, 95, 136, 107, 'S')

      doc.setFont("helvetica","");
      doc.setFontSize(7)
      doc.text("Declared Value for Carriage", 138, 97.5, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(9)
      doc.text("NVD", 140, 104, {align: "left"});

      doc.line(169, 95, 169, 107, 'S')

      doc.setFont("helvetica","");
      doc.setFontSize(7)
      doc.text("Declared Value for Customs", 173, 97.5, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(9)
      doc.text("NCV", 175, 104, {align: "left"});

      doc.line(line_left_to_mid, 107, line_mid_to_right, 107, 'S')

      doc.setFont("helvetica","");
      doc.setFontSize(7)
      doc.text("Amount of Insurance", 108, 110, {align: "left"});

      doc.line(136, 107, 136, 120, 'S')

      doc.setFont("helvetica","");
      doc.setFontSize(7)
      doc.text("INSURANCE - if carrier offers insurance, and such insurance is ", 137, 110, {align: "left"});
      doc.text("requested in accordance with the conditions thereof, indicate amount", 137, 113, {align: "left"});
      doc.text('to be insured in figures in box marked "Amount of Insurance".', 137, 116, {align: "left"});

      doc.line(line_left_to_mid, end_line_mid, line_mid_to_right, end_line_mid, 'S')

      // Kotak tengah
      doc.setFont("helvetica","");
      doc.setFontSize(7)
      doc.text("Handling Information", 4, 123, {align: "left"});

      doc.lines([[-37,0],[0,12]], line_mid_to_right, end_line_mid+13, [1,1], 'S', false)

      doc.setFont("helvetica","");
      doc.setFontSize(8)
      doc.text("SCI", 185, end_line_mid+16, {align: "left"});

      let line_horizontal_mid_y = end_line_mid+25
      let line_horizontal_mid_y_2 = line_horizontal_mid_y+60
      let line_between_horizontal_mid = line_horizontal_mid_y_2-line_horizontal_mid_y

      // Garis tengah Horizontal
      doc.line(3, line_horizontal_mid_y, line_mid_to_right, line_horizontal_mid_y, 'S')

      let curry = line_horizontal_mid_y+3
      let currx = 4
      doc.setFont("helvetica","");
      doc.setFontSize(7)
      doc.text("No. of", currx, curry, {align: "left"});
      doc.text("Pieces RCP", currx, curry = curry+3, {align: "left"});

      doc.line(3, line_horizontal_mid_y+8, 16, line_horizontal_mid_y+8, 'S')

      doc.line(3, line_horizontal_mid_y_2-10, 16, line_horizontal_mid_y_2-10, 'S')

      doc.line(16, line_horizontal_mid_y, 16, line_horizontal_mid_y_2, 'S')

      doc.setFont("helvetica","");
      doc.setFontSize(7)
      doc.text("Gross Weight", currx = currx+15, curry = curry-1, {align: "left"});

      doc.line(16, line_horizontal_mid_y+8, 36, line_horizontal_mid_y+8, 'S')

      doc.line(16, line_horizontal_mid_y_2-10, 36, line_horizontal_mid_y_2-10, 'S')

      doc.line(36, line_horizontal_mid_y, 36, line_horizontal_mid_y_2, 'S')

      doc.setFont("helvetica","");
      doc.setFontSize(6)
      doc.text("kg", currx = currx+17.5, curry-1, {align: "left"});
      doc.text("lb", currx, curry+2, {align: "left"});

      doc.line(36, line_horizontal_mid_y+8, 40, line_horizontal_mid_y+8, 'S')

      doc.setDrawColor(0);
      doc.setFillColor(231, 219, 213);  // Warna Kotak
      doc.rect(currx = currx+2.5, line_horizontal_mid_y, 3, line_between_horizontal_mid, 'FD')

      doc.setFont("helvetica","");
      doc.setFontSize(7)
      doc.text("Rate Class", currx = currx+4, curry-2, {align: "left"});

      doc.line(currx = currx+3, line_horizontal_mid_y+4, currx+17, line_horizontal_mid_y+4, 'S')
      doc.line(currx, line_horizontal_mid_y+4, currx, line_horizontal_mid_y_2, 'S')

      doc.setFont("helvetica","");
      doc.setFontSize(5)
      doc.text("Commodity", currx+4, curry+0.5, {align: "left"});
      doc.text("Item No. ", currx+5, curry+2, {align: "left"});

      doc.line(currx, line_horizontal_mid_y+8, currx = currx+15, line_horizontal_mid_y+8, 'S')

      doc.setDrawColor(0);
      doc.setFillColor(231, 219, 213);  // Warna Kotak
      doc.rect(currx, line_horizontal_mid_y, 3, line_between_horizontal_mid, 'FD')

      doc.setFont("helvetica","");
      doc.setFontSize(7)
      doc.text("Chargeable", currx = currx+6, curry-1.5, {align: "left"});
      doc.text("Weight", currx = currx+2, curry+1, {align: "left"});

      doc.line(currx-5, line_horizontal_mid_y+8, currx = currx+14, line_horizontal_mid_y+8, 'S')

      doc.setDrawColor(0);
      doc.setFillColor(231, 219, 213);  // Warna Kotak
      doc.rect(currx, line_horizontal_mid_y, 3, line_between_horizontal_mid, 'FD')

      doc.setFont("helvetica","");
      doc.setFontSize(7)
      doc.text("Rate", currx = currx+6, curry-1.5, {align: "left"});
      doc.line(currx+11, curry-3, currx, curry+2, 'S')
      doc.text("Charge", currx+7.5, curry+1.5, {align: "left"});

      doc.line(currx-3, line_horizontal_mid_y+8, currx = currx+20, line_horizontal_mid_y+8, 'S')

      doc.setDrawColor(0);
      doc.setFillColor(231, 219, 213);  // Warna Kotak
      doc.rect(currx, line_horizontal_mid_y, 3, line_between_horizontal_mid, 'FD')

      doc.setFont("helvetica","");
      doc.setFontSize(7)
      doc.text("Total", currx = currx+17, curry+1, {align: "left"});

      let colly_y = line_horizontal_mid_y+13
      let colly_seq = 0
      let colly_grossweight_ttl = 0
      for (let i = 0; i < dataDtl.length; i++) {
          let current_colly = i + 1
          if (i < 1) {
              doc.setFont("helvetica","");
              doc.setFontSize(9)
              doc.text(`${current_colly}`, 6, colly_y, {align: "left"});

              doc.setFont("helvetica","");
              doc.setFontSize(9)
              doc.text(dataDtl[i].gross_weight, 19, colly_y, {align: "left"});

              doc.setFont("helvetica","");
              doc.setFontSize(9)
              doc.text("K", 36.5, colly_y, {align: "left"});

              doc.setFont("helvetica","");
              doc.setFontSize(9)
              doc.text("N", 43, colly_y, {align: "left"});

              doc.setFont("helvetica","");
              doc.setFontSize(9)
              doc.text("000"+current_colly, 48, colly_y, {align: "left"});

              doc.setFont("helvetica","");
              doc.setFontSize(9)
              doc.text(dataDtl[i].chargeable_weight, 67, colly_y, {align: "left"});

              doc.setFont("helvetica","");
              doc.setFontSize(9)
              doc.text("As Agreed", 89, colly_y, {align: "left"});

              doc.setFont("helvetica","");
              doc.setFontSize(9)
              doc.text("As Agreed", 116, colly_y, {align: "left"});

              doc.setFont("helvetica","");
              doc.setFontSize(9)
              doc.text(dataDtl[i].goodstype_name, 151, colly_y, {align: "left"});
          }else{
              doc.setFont("helvetica","");
              doc.setFontSize(9)
              doc.text(`${current_colly}`, 6, colly_y+4, {align: "left"});

              doc.setFont("helvetica","");
              doc.setFontSize(9)
              doc.text(dataDtl[i].gross_weight, 19, colly_y+4, {align: "left"});

              doc.setFont("helvetica","");
              doc.setFontSize(9)
              doc.text("K", 36.5, colly_y+4, {align: "left"});

              doc.setFont("helvetica","");
              doc.setFontSize(9)
              doc.text("N", 43, colly_y+4, {align: "left"});

              doc.setFont("helvetica","");
              doc.setFontSize(9)
              doc.text("000"+current_colly, 48, colly_y+4, {align: "left"});

              doc.setFont("helvetica","");
              doc.setFontSize(9)
              doc.text(dataDtl[i].chargeable_weight, 67, colly_y+4, {align: "left"});

              doc.setFont("helvetica","");
              doc.setFontSize(9)
              doc.text("As Agreed", 89, colly_y+4, {align: "left"});

              doc.setFont("helvetica","");
              doc.setFontSize(9)
              doc.text("As Agreed", 116, colly_y+4, {align: "left"});

              doc.setFont("helvetica","");
              doc.setFontSize(9)
              doc.text(dataDtl[i].goodstype_name, 151, colly_y+4, {align: "left"});
          }
          colly_seq+=1
          colly_grossweight_ttl+=parseFloat(dataDtl[i].gross_weight)
      }

      doc.text(`${colly_seq}`, 6, line_horizontal_mid_y_2-5, {align: "left"});
      doc.text(`${parseFloat(colly_grossweight_ttl)}`, 19, line_horizontal_mid_y_2-5, {align: "left"});

      doc.line(currx-14, line_horizontal_mid_y+8, currx = currx+20, line_horizontal_mid_y+8, 'S')

      doc.line(currx-34, line_horizontal_mid_y_2-10, currx, line_horizontal_mid_y_2-10, 'S')

      doc.setDrawColor(0);
      doc.setFillColor(231, 219, 213);  // Warna Kotak
      doc.rect(currx, line_horizontal_mid_y, 3, line_between_horizontal_mid, 'FD')

      doc.setFont("helvetica","");
      doc.setFontSize(7)
      doc.text("Nature and Quality of Goods", currx = currx+15, curry-2, {align: "left"});
      doc.text("(Incl. Dimensions or Volume)", currx, curry+1, {align: "left"});

      doc.line(currx-12, line_horizontal_mid_y+8, line_mid_to_right, line_horizontal_mid_y+8, 'S')

      // doc.setFont("helvetica","");
      // doc.setFontSize(9)
      // doc.text("GENCO", currx-10, line_horizontal_mid_y+20, {align: "left"});
      //
      // doc.setFont("helvetica","");
      // doc.setFontSize(8)
      // doc.text("LWH IN CMS 30 X 30 X 40 X 1", currx-10, line_horizontal_mid_y+24, {align: "left"});

      // doc.setFont("helvetica","");
      // doc.setFontSize(8)
      // doc.text("0.036", currx-10, line_horizontal_mid_y+28, {align: "left"});

      // Garis tengah Horizontal 2
      doc.line(3, line_horizontal_mid_y_2, line_mid_to_right, line_horizontal_mid_y_2, 'S')

      doc.lines([[4,2.5],[14,0],[4,-2.5]], 3, line_horizontal_mid_y_2, [1,1], 'S', false)

      doc.setFont("helvetica","");
      doc.setFontSize(6)
      doc.text("Prepaid", 11, line_horizontal_mid_y_2+2, {align: "left"});

      doc.lines([[4,2.5],[23,0],[4,-2.5]], 25, line_horizontal_mid_y_2, [1,1], 'S', false)

      doc.text("Weight Charge", 35, line_horizontal_mid_y_2+2, {align: "left"});

      doc.lines([[4,2.5],[14,0],[4,-2.5]], 56, line_horizontal_mid_y_2, [1,1], 'S', false)

      doc.text("Collect", 63, line_horizontal_mid_y_2+2, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(8)
      doc.text("As Agreed", 7, line_horizontal_mid_y_2+7, {align: "left"});

      doc.line(42, line_horizontal_mid_y_2+2.4, 42, line_horizontal_mid_y_2+9, 'S')

      doc.line(3, line_horizontal_mid_y_2+9, 78, line_horizontal_mid_y_2+9, 'S')

      doc.lines([[4,2.5],[28,0],[4,-2.5]], 23, line_horizontal_mid_y_2+9, [1,1], 'S', false)

      doc.setFont("helvetica","");
      doc.setFontSize(6)
      doc.text("Valuation Charge", 34, line_horizontal_mid_y_2+11, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(8)
      doc.text("0.00", 7, line_horizontal_mid_y_2+15, {align: "left"});

      doc.line(42, line_horizontal_mid_y_2+11.4, 42, line_horizontal_mid_y_2+18, 'S')

      doc.line(3, line_horizontal_mid_y_2+18, 78, line_horizontal_mid_y_2+18, 'S')

      doc.lines([[4,2.5],[14,0],[4,-2.5]], 31, line_horizontal_mid_y_2+18, [1,1], 'S', false)

      doc.setFont("helvetica","");
      doc.setFontSize(6)
      doc.text("Tax", 40, line_horizontal_mid_y_2+20, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(8)
      doc.text("As Agreed", 7, line_horizontal_mid_y_2+23, {align: "left"});

      doc.line(42, line_horizontal_mid_y_2+20.4, 42, line_horizontal_mid_y_2+27, 'S')

      doc.line(3, line_horizontal_mid_y_2+27, 78, line_horizontal_mid_y_2+27, 'S')

      doc.lines([[4,2.5],[52,0],[4,-2.5]], 11, line_horizontal_mid_y_2+27, [1,1], 'S', false)

      doc.setFont("helvetica","");
      doc.setFontSize(6)
      doc.text("Total Other Charges Due Agent", 29, line_horizontal_mid_y_2+29, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(8)
      doc.text("As Agreed", 7, line_horizontal_mid_y_2+34, {align: "left"});

      doc.line(42, line_horizontal_mid_y_2+29.4, 42, line_horizontal_mid_y_2+36, 'S')

      doc.line(3, line_horizontal_mid_y_2+36, 78, line_horizontal_mid_y_2+36, 'S')

      doc.lines([[4,2.5],[52,0],[4,-2.5]], 11, line_horizontal_mid_y_2+36, [1,1], 'S', false)

      doc.setFont("helvetica","");
      doc.setFontSize(6)
      doc.text("Total Other Charges Due Carrier", 29, line_horizontal_mid_y_2+38, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(8)
      doc.text("As Agreed", 7, line_horizontal_mid_y_2+43, {align: "left"});

      doc.line(42, line_horizontal_mid_y_2+29.4, 42, line_horizontal_mid_y_2+45, 'S')

      doc.setDrawColor(0);
      doc.setFillColor(231, 219, 213);  // Warna Kotak
      doc.rect(3, line_horizontal_mid_y_2+45, 39, 11, 'FD')

      doc.setDrawColor(0);
      doc.setFillColor(231, 219, 213);  // Warna Kotak
      doc.rect(42, line_horizontal_mid_y_2+45, 36, 11, 'FD')

      doc.lines([[4,2.5],[21,0],[4,-2.5]], 8, line_horizontal_mid_y_2+56, [1,1], 'S', false)

      doc.setFont("helvetica","");
      doc.setFontSize(6)
      doc.text("Total Prepaid", 17, line_horizontal_mid_y_2+58, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(8)
      doc.text("As Agreed", 7, line_horizontal_mid_y_2+63, {align: "left"});

      doc.lines([[4,2.5],[21,0],[4,-2.5]], 45, line_horizontal_mid_y_2+56, [1,1], 'S', false)

      doc.setFont("helvetica","");
      doc.setFontSize(6)
      doc.text("Total Collect", 54, line_horizontal_mid_y_2+58, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(8)
      doc.text("0.00", 45, line_horizontal_mid_y_2+63, {align: "left"});

      doc.line(42, line_horizontal_mid_y_2+54.4, 42, line_horizontal_mid_y_2+75, 'S')

      doc.line(3, line_horizontal_mid_y_2+65, 78, line_horizontal_mid_y_2+65, 'S')

      doc.setDrawColor(0);
      doc.setFillColor(231, 219, 213);  // Warna Kotak
      doc.rect(3, line_horizontal_mid_y_2+65, 39, 10, 'FD')

      doc.lines([[4,2.5],[31,0],[4,-2.5]], 3, line_horizontal_mid_y_2+65, [1,1], 'S', false)

      doc.setFont("helvetica","");
      doc.setFontSize(6)
      doc.text("Currency Conversion Rates", 11, line_horizontal_mid_y_2+67, {align: "left"});

      doc.setFillColor(231, 219, 213);  // Warna Kotak
      doc.setDrawColor(0);
      doc.rect(42, line_horizontal_mid_y_2+65, 36, 10, 'FD')

      doc.lines([[4,2.5],[28,0],[4,-2.5]], 42, line_horizontal_mid_y_2+65, [1,1], 'S', false)

      doc.setFont("helvetica","");
      doc.setFontSize(6)
      doc.text("CC Charges in Dest. Currency", 47, line_horizontal_mid_y_2+67, {align: "left"});

      // Garis tengah vertikal mentok bawah
      doc.line(78, line_horizontal_mid_y_2, 78, line_horizontal_mid_y_2+75, 'S')

      doc.setFont("helvetica","");
      doc.setFontSize(7)
      doc.text("Other Charges", 79, line_horizontal_mid_y_2+3, {align: "left"});

      doc.line(78, line_horizontal_mid_y_2+27, line_mid_to_right, line_horizontal_mid_y_2+27, 'S')

      doc.setFont("helvetica","");
      doc.setFontSize(9)
      doc.text("I hereby testify that the particulars on the face hereof are correct and taht insofar as any part of the", 80, line_horizontal_mid_y_2+30, {align: "left"});
      doc.text("consignment contains dangerous goods. I hereby certify that the contents of this consignment are", 80, line_horizontal_mid_y_2+33, {align: "left"});
      doc.text("fully and accurately described above by proper shipping name and are classified, packaged,", 80, line_horizontal_mid_y_2+36, {align: "left"});
      doc.text("marked and labeled, and in proper condition for carriage by air according to applicable national", 80, line_horizontal_mid_y_2+39, {align: "left"});
      doc.text("governmental regulations.", 80, line_horizontal_mid_y_2+42, {align: "left"});

      doc.text(dataMst[0].cust_name, 83, line_horizontal_mid_y_2+47, {align: "left"});
      doc.text(dataMst[0].airport_departure_code, 83, line_horizontal_mid_y_2+50, {align: "left"});

      doc.setLineDash([1, 1], 0);
      doc.line(78, line_horizontal_mid_y_2+52, line_mid_to_right, line_horizontal_mid_y_2+52, 'S')

      doc.setLineDash([0, 0], 0);
      doc.line(78, line_horizontal_mid_y_2+56, line_mid_to_right, line_horizontal_mid_y_2+56, 'S')

      doc.setFont("helvetica","");
      doc.setFontSize(7)
      doc.text("Signature of Shipper or his Agent", (line_mid_to_right/2)+20, line_horizontal_mid_y_2+54.5, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(9)
      doc.text(dataMst[0].order_date, 83, line_horizontal_mid_y_2+69, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(7)
      doc.text("Executed on (date)", 83, line_horizontal_mid_y_2+73, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(9)
      doc.text(dataMst[0].airport_departure_code, (line_mid_to_right/2)+30, line_horizontal_mid_y_2+69, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(7)
      doc.text("at (place)", (line_mid_to_right/2)+30, line_horizontal_mid_y_2+73, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(9)
      doc.text(dataMst[0].cust_name, line_mid_to_right-1, line_horizontal_mid_y_2+69, {align: "right"});

      doc.setFont("helvetica","");
      doc.setFontSize(7)
      doc.text("Signature of Issuing Carrier or its Agent", line_mid_to_right-1, line_horizontal_mid_y_2+73, {align: "right"});

      doc.setLineDash([1, 1], 0);
      doc.line(78, line_horizontal_mid_y_2+70, line_mid_to_right, line_horizontal_mid_y_2+70, 'S')

      doc.setLineDash([0, 0], 0);

      doc.setFillColor(231, 219, 213);  // Warna Kotak
      doc.setDrawColor(0);
      doc.rect(3, line_horizontal_mid_y_2+75, 39, 10, 'FD')

      doc.setFont("helvetica","");
      doc.setFontSize(8)
      doc.text("For Carrier's Use Only", 10, line_horizontal_mid_y_2+79, {align: "left"});
      doc.text("at Destination", 14, line_horizontal_mid_y_2+82, {align: "left"});

      doc.setFillColor(231, 219, 213);  // Warna Kotak
      doc.setDrawColor(0);
      doc.rect(42, line_horizontal_mid_y_2+75, 36, 10, 'FD')

      doc.lines([[4,2.5],[28,0],[4,-2.5]], 42, line_horizontal_mid_y_2+75, [1,1], 'S', false)

      doc.setFont("helvetica","");
      doc.setFontSize(6)
      doc.text("Charges at Destination", 51, line_horizontal_mid_y_2+77, {align: "left"});

      doc.setFillColor(231, 219, 213);  // Warna Kotak
      doc.setDrawColor(0);
      doc.rect(78, line_horizontal_mid_y_2+75, 36, 10, 'FD')

      doc.lines([[4,2.5],[28,0],[4,-2.5]], 78, line_horizontal_mid_y_2+75, [1,1], 'S', false)

      doc.setFont("helvetica","");
      doc.setFontSize(6)
      doc.text("Total Collect Charges", 87, line_horizontal_mid_y_2+77, {align: "left"});

      doc.setFont("helvetica","");
      doc.setFontSize(16)
      doc.text("POWERED BY ANTARPORT", line_mid_to_right, line_horizontal_mid_y_2+83, {align: "right"});

      doc.save(pdfName + dataMst[0].ord_manifestno + '.pdf');

      // Gak download
      // window.open(URL.createObjectURL(doc.output("blob")))
  }
}




