<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useListTable from '@/comp-functions/useListTable';
import {formatNumber} from '@/utils/formatter'
import moment from 'moment'
import printSmu from '@/components/print-smu'
import printInvoiceSmu from '@/components/print-invoice-smu'
import DatePicker from "vue2-datepicker";
import { ref, onMounted } from '@vue/composition-api'

export default {
  page: {
    title: "Order List",
    meta: [{ name: "description", content: appConfig.description }]
  },

  setup () {
      const { 
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists } = useListTable({ url: false })
      const tableColumns = [
        { key: "ord_datetime", label: "Order Date", sortable: true },
        { key: "colordno", label: "Order No.", sortable: true },
        { key: "schedule.vendor.name", label: "Airline", sortable: true },
        { key: "schedule.sch_departure_datetime", label: "Departure", sortable: true },
        { key: "ord_manifestno", label: "SMU No.", sortable: true },
        { key: "ord_weight", label: "Total Weight", sortable: true, tdClass: "text-right",
          formatter: value => {return formatNumber(value)}
        },
        {key: 'ord_gtotalcharge', label: 'Grand Total Charge', tdClass: "text-right",
          formatter: value => {return formatNumber(value)}
        },
        { key: "", label: "Payment Status", thClass: 'text-center', tdClass: 'text-center', sortable: true },
        { key: "", label: "Goods Status", thClass: 'text-center', tdClass: 'text-center', sortable: true },
        { key: "action", thClass: 'text-center', tdClass: 'text-center', label: "#" },
          { key: "actionSMU", thClass: 'text-center', tdClass: 'text-center', label: "#" }
    ]

    const columnToBeFilter = [
      'ord_datetime',
      'colordno',
      'schedule.vendor.name',
      'schedule.sch_departure_datetime',
      'ord_manifestno',
      'ord_weight',
      'ord_gtotalcharge'

    ]
    
    const date1 = new Date()
    const date2 = new Date()
    date1.setDate(date2.getDate() - 5);

    const paramdate = ref([moment(date1).format('DD-MM-YYYY'), moment(date2).format('DD-MM-YYYY')])

    const printLabelsmu =  (order_id) => {
      printSmu(order_id)
    }
    const reloaddatalist = async () => {
      let url = '/master/order'
      let params = {
        start_orddate: paramdate.value[0],
        end_orddate:  paramdate.value[1],
      }
      await fetchLists(url,params)
    }

    const createSmu = async (inv_id) => {
        printInvoiceSmu(inv_id)
    }

    onMounted(() => {
      reloaddatalist()
    })
    return {
      printLabelsmu,
      formatNumber,
      tableColumns,
      columnToBeFilter,
      paramdate,
      reloaddatalist,
      
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      createSmu,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    }
    // onMounted(async () => {
    //   await fetchLists('/master/order')
    //   this.totalRows = this.items.length;
    // })
    
  },
  components: { Layout, PageHeader, DatePicker },
  data() {
    return {
      title: "Order List",
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "derparture_date",
      sortDesc: false
    };
  },
   methods: {
    onFiltered(filteredItems) {
      this.totalList = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <label class="col-md-10 col-sm-12 d-inline-flex align-items-center p-0">
                  Order Date :
                  <date-picker class="col-md-7" @close="reloaddatalist()"  v-model="paramdate" format='DD-MM-YYYY' valueType="format"  range append-to-body lang="en"></date-picker>
                </label>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="searchQuery"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                id="refListTable"
                ref="refListTable"
                no-provider-paging
                no-provider-filtering
                :filter-included-fields="columnToBeFilter"
                :fields="tableColumns"
                :items="itemLists"
                :per-page="perPage"
                :current-page="currentPage"
                :filter="searchQuery"
                responsive
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                :busy="isBusy"
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
                class="position-relative"
              >
                 <template v-slot:cell(action)="data">
                  <b-button size="sm" variant="primary" @click="printLabelsmu(data.item.ord_id)" v-if="data.item.ord_manifestno !== null">
                    <i class="ri-printer-line"></i>
                    Print Label
                  </b-button>
                </template>
                <template v-slot:cell(actionSMU)="data">
                    <b-button size="sm" variant="primary" @click="createSmu(data.item.ord_id)" v-if="data.item.ord_manifestno !== null">
                        <i class="ri-printer-line"></i>
                        Print SMU
                    </b-button>
                </template>
                 <template v-slot:cell(colordno)="data">
                   <router-link :to="{ name: 'apps-order-detail', params: { p_ordno: data.item.ord_no }}">{{data.item.ord_no}}</router-link>
                </template>
                </b-table>
            </div>
            <div class="row">
               <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                   Showing
                    <b-form-select class="col-sm-12 col-md-2" v-model="perPage" size="sm" :options="perPageOptions"></b-form-select>{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
                  </label>
                </div>
              </div>
              
              <div class="col-md-6 col-xs-12">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalList" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
