import { jsPDF } from "jspdf";
import useHttp from '@/comp-functions/useHttp'

export default function printSmu(props) {

  const { $get } = useHttp()
  const getData = async () => {
    const {data} = await $get({ url: 'master/order/' + props})
    generateLabel(data)
  }
  getData()
  const generateLabel = (data) => {
    console.log(data)
    let pdfName = 'AP_SMU_label_';
    let imgUrl = '';
    let cury = 6.5;
    let curcolly = 1;
    let x_start = 3;
    let x_end = 100;
    let x_center = 52;
    let y_end = 143;
    let doc = new jsPDF({
      format: [104, 148]
    });

    data.orderDetail.forEach(rowdetails => {
      for (let i = 0, len = rowdetails.odd_collies; i < len; i++) {
        // data.orderDetail.i === rowdetails
        if (curcolly > 1) {
          doc.addPage();
        }

      cury = 6.5;
      
      // if {

      // }
      //rowdetails.ord_collies
    
    imgUrl = process.env.VUE_APP_BASEURL + require("@/assets/images/vendorlogo/"+ data.schedule.vendor.vnd_logo_fe)
    // console.log(imgUrl)
    doc.addImage(imgUrl, "PNG", x_center-17, cury, 40, 18);
    cury+=27;
    doc.setFontSize(27);
    doc.setFont("helvetica", "bold");
    doc.text((data.schedule.vendor.name), x_center, cury, {align: "center"});
    cury+=4;
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.4);
    doc.line(x_start, cury, x_end, cury);
    
    cury+=4;
    doc.setFont("helvetica", "normal");
    doc.setFontSize(9.5);
    doc.text("Air Waybill No.", x_start, cury);

    cury+=11;
    doc.setFontSize(31);
    doc.text((data.ord_manifestno), x_center, cury, {align: "center"});

    cury+=5.3;
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.4);
    doc.line(x_start, cury, x_end, cury);
    
    cury+=4;
    doc.setFontSize(9.5);
    doc.text(("Destination"), x_start, cury);
    doc.text("Origin", (x_center+3), cury);

    cury+=12;
    doc.setFontSize(34);
    doc.setFont("helvetica", "bold");
    doc.text((data.schedule.destinationport.prt_initial), (x_center-(x_center/2)), cury, {align: "center"});
    doc.setFont("helvetica", "normal");
    doc.text((data.schedule.originport.prt_initial), (x_center+(x_center/2)), cury, {align: "center"});

    cury+=5;
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.4);
    doc.line(x_start, cury, x_end, cury);
    
    cury+=4;
    doc.setFontSize(9.5);
    doc.setFont("helvetica", "normal");
    doc.text("Total Number of Collies", x_start, cury);
    doc.text("Weight", (x_center+3), cury);

    cury+=10;
    doc.setFontSize(25);
    doc.setFont("helvetica", "bold");
    doc.text(curcolly++ + " of " + data.ord_collies , (x_center-(x_center/2)), cury, {align: "center"});
    doc.setFont("helvetica", "normal");
    doc.text(rowdetails.odd_weight_per_colly +"Kg", (x_center+(x_center/2)), cury, {align: "center"});
    
    cury+=4;
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.4);
    doc.line(x_start, cury, x_end, cury);
    
    cury+=9;
    doc.setFontSize(21);
    doc.text(rowdetails.goodsType.descr_en , x_center, cury, {align: "center"});

    cury+=5;
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.4);
    doc.line(x_start, cury, x_end, cury);

    cury+=8;
    doc.setFontSize(11);
    doc.text("Flight No. : " + data.schedule.sch_flight_no, x_start, cury);
    cury+=7;
    doc.text("Departure : " + data.schedule.sch_departure_date, x_start, cury);

    imgUrl = process.env.VUE_APP_BASEURL + require("@/assets/images/logo-light.png")
    doc.addImage(imgUrl, "PNG", (x_start+69.8), (y_end-27), 28, 22.5);
    doc.setFontSize(10);
    doc.text("www.antarport.id", x_end-2, y_end, {align: "right"});

  }

  });

    doc.save(pdfName + data.ord_manifestno + '.pdf');
  }
}